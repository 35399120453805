<ion-content [fullscreen]="true" class="ion-padding">

  <div class="login-container">
    <div class="login-box">
      <div class="login-image">
        <img src="../../../assets/totalum-logo-blue-text.png" alt="Totalum logo">
      </div>

      <div class="login-title">
        Regístrate o inicia sesión
      </div>

      <form [formGroup]="loginForm">
        <ion-list>

        <b>Introduce tu email</b>

        <ion-item lines="full" class="form-inputs-margin">
          <ion-input 
          type="email"
          placeholder="Introduce tu correo electrónico"
          [clearInput]="true"
          label="Correo electrónico" label-placement="stacked"
          formControlName="email"
          ></ion-input>
        </ion-item>
        <div class="form-input-error" color="danger" *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched">
          <ng-container *ngIf="loginForm.get('email')?.hasError('required')">El correo electrónico es obligatorio</ng-container>
          <ng-container *ngIf="loginForm.get('email')?.hasError('email')">El formato de correo es inválido</ng-container>
        </div>

        <div style="color: rgb(92, 91, 91); font-size: 15px;">
          <br>
          Al darle click a entrar aceptas los <a href="https://www.totalum.app/terms-and-conditions" target="_blank">términos y condiciones</a> y la <a href="https://www.totalum.app/politica-de-privacidad" target="_blank">política de privacidad</a> de Totalum.
          </div>
        
          <div class="button">
            <ion-button mode="ios" class="button-margin-top" (click)="onSubmit()">
              <ion-label>Entrar</ion-label>
            </ion-button>
          </div>
          
      </ion-list>

        
      </form>
    </div>


  </div>

<!--
  <div style="padding:20%;">
  <img src="../../../assets/totalum-logo-blue-text.png" style="width: 60%;margin-left: 20%;margin-top: -200px;margin-bottom: 40px;">

    <form [formGroup]="loginForm">

      <ion-list>

        <b>Introduce tu email</b>

        <ion-item lines="full" class="form-inputs-margin">
          <ion-input 
          type="email"
          placeholder="Introduce tu correo electrónico"
          [clearInput]="true"
          label="Correo electrónico" label-placement="stacked"
          formControlName="email"
          ></ion-input>
        </ion-item>
        <div class="form-input-error" color="danger" *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched">
          <ng-container *ngIf="loginForm.get('email')?.hasError('required')">El correo electrónico es obligatorio</ng-container>
          <ng-container *ngIf="loginForm.get('email')?.hasError('email')">El formato de correo es inválido</ng-container>
        </div>
      </ion-list>

      <div style="color: rgb(92, 91, 91);">
      <br>
      Al darle click a entrar aceptas los <a href="https://www.totalum.app/terms-and-conditions" target="_blank">términos y condiciones</a> y la <a href="https://www.totalum.app/politica-de-privacidad" target="_blank">política de privacidad</a> de Totalum.
      </div>
    
      <ion-button mode="ios" class="button-margin-top" (click)="onSubmit()">
        <ion-label>Entrar</ion-label>
      </ion-button>

    </form>

  </div>-->

</ion-content>
